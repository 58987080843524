<template>
  <section class="content">
    <table class="table table-hover" ref="tbl_pengajuan_po">
      <thead>
        <tr>
          <th>Nomor PO</th>
          <th>Tanggal</th>
          <th>Diajukan Oleh</th>
          <th>Cabang</th>
          <th>Nilai Total</th>
          <th>Keperluan</th>
          <th>Disetujui Oleh</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document" style="max-width:600px">
        <div class="modal-content">
          <form role="form" @submit="submitForm" id="formPO">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <p v-if="errors.length" class="alert alert-danger">
                <b>Please correct the following error(s):</b>
              </p> -->
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">Staff</label>
                  <v-select :options="optStaff" v-model="form.staff_id" :reduce="opt => opt.id" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Tanggal Pemakaian</label>
                  <datepicker v-model="form.date" id="tanggal" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Keterangan</label>
                  <input
                    id="keterangan"
                    class="form-control"
                    v-model="form.keterangan"
                    type="text"
                    name="keterangan"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Nilai Total</label>
                  <input
                    id="nilai_total"
                    class="form-control"
                    v-model="form.nilai_total"
                    type="number"
                    name="Nilai Total"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Cabang</label> <br />
                  <v-select :options="optBranch" v-model="form.branch_id" :reduce="opt => opt.id" />
                </div>
              </div>
              <div class="row" style="width:100%">
                <table class="table table-hover" ref="tablebarang" style="width:100%" id="tablebarang">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Barang</th>
                            <th>Harga</th>
                            <th>QTY</th>
                            <th>Note</th>
                            <th>Note</th>
                            <th>Total</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
                <div class="card">
                    <div class="card-body">
                        <a class="btn btn-default" v-on:click="addItem">Tambah item</a>
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import datepicker from "@/components/Datepicker";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

export default {
  name: "",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      formTitle: "Tambah PO",
      tbl: '',
      optStaff: [],
      optBranch: [],
      optBarang: [],
      counterHarga: 0,
      counterQty: 0,
      counterTotal: 0,
      form: {
        staff_id: "",
        date: "",
        branch_id: "",
        keterangan: "",
        nilai_total: "",
        items: [],
        type: 3
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    authFetch('/administrasi/pengajuan_budget/staff')
      .then(res => {
          if (res.status === 201) {

          } else if (res.status === 400) {}
          return res.json();
      })
      .then(js => {
          this.optStaff = js.data;
      })

    authFetch('/administrasi/pengajuan_budget/branch')
      .then(res => {
          if (res.status === 201) {

          } else if (res.status === 400) {}
          return res.json();
      })
      .then(js => {
          this.optBranch = js.data;
      })
  },
  components: {
    vSelect,
    datepicker,
    datePicker,
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },

    loadBarang: async function() {
        let vm = this;
        const res = await authFetch('/administrasi/pengajuan_budget/barang');
        vm.optBarang = await res.json();
    },

    addItem: function() {
        this.counterHarga = 0;
        this.counterQty = 0;
        this.counterTotal = 0;
        var nRow = this.tbl.fnAddRow('<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Delete</a></div>');
        $('td .btn.edit', nRow).click();
    },

    submitForm: function (ev) {
      const e = this.$refs;
      let vm = this;

      var table = $('#tablebarang').DataTable();
      var params = table.context[0].aoData;
      var items = [];
      Object.keys(params).map(function(key, index) {
        items.push(params[key]._aData)
      });
      this.form.items = items
      this.form.type = 3

      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");

      var dataBarang = '';
      items.map(function(val, key){
        dataBarang += Object.keys(val)
        .map(
          (key) =>
            encodeURIComponent(key) + "[]=" + encodeURIComponent(val[key])
        )
        .join("&");
      })

      var urlSubmit = "/administrasi/pengajuan_budget";
      if (this.method == "PUT")
        urlSubmit = "/administrasi/pengajuan_budget/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data+'&'+dataBarang,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  async mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tbl_pengajuan_po, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/administrasi/pengajuan_budget/3",
      columns: [
        { data: "code" },
        { data: "date" },
        { data: "staff" },
        { data: "branch" },
        { data: "nilai_total" },
        { data: "keterangan" },
        { data: "approval_name" },
        { data: "status" },
      ],
      filterBy: [1, 2, 3, 4, 5],
      frame: true,
      rowCallback: function (row, data) {
        let idr = Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: "IDR",
            useGrouping: true,
            maximumSignificantDigits: 3,
        }); 
        $("td:eq(4)", row).html(idr.format(data.nilai_total));

        if (data.status == '1') {
          $("td:eq(7)", row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-success">Approved</span>');
        } else if (data.status == '0') {
          $("td:eq(7)", row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-primary">Waiting</span>');
        } else {
          $("td:eq(7)", row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-danger">Rejected</span>');
        }

        if (data.tipe_pengajuan == 1) {
          $("td:eq(4)", row).html("Perlengkapan");
        }
        if (data.tipe_pengajuan == 2) {
          $("td:eq(4)", row).html("Perlengkapan ATK");
        }
        if (data.tipe_pengajuan == 3) {
          $("td:eq(4)", row).html("Peralatan dan Biaya Operational");
        }
        if (data.tipe_pengajuan == 4) {
          $("td:eq(4)", row).html("Biaya lain-lain");
        }
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah PO";
          this.tbl.api().ajax.url(window.$apiUrl+"/administrasi/pengajuan_budget/item/").load();
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;console.log(evt.data)
          self.form.status = evt.data.status == 1 ? true : false; //operator ternary
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit PO";
          // this.tbl.api().ajax.reload();
          this.tbl.api().ajax.url(window.$apiUrl+"/administrasi/pengajuan_budget/item/"+self.form.id).load();
          $(e.formDialog).modal("show");
          this.counterHarga = 0;
          this.counterQty = 0;
          this.counterTotal = 0;
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/administrasi/pengajuan_budget/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
    });
    await this.loadBarang();
    this.optBarang.data[0] = 'Pilih barang';
    let barang = this.tbl = createTable(e.tablebarang, {
            "title": "Rincian Barang",
            serverSide: false,
            filter: false,
            paging: false,
            scrollX: true,
            "ajax": "/administrasi/pengajuan_budget/item",
            "columns": [
                { "data": "ids", visible: false},
                { "data": "barang", sType:'select', source: this.optBarang.data, change: function(evt, data){
                    let cek = barang.DataTable.row().data()
                    barang.fnUpdate(data.id, evt, 5, false);
                    $('td:nth-child(2) .dt',evt).val(0);
                    $('td:nth-child(3) .dt',evt).val(0);
                }},
                { "data": "harga", change: function(evt, data){
                    self.counterHarga = data
                    self.counterTotal = data * self.counterQty
                    $('td:nth-child(5) .dt',evt).val(self.counterTotal);
                }},
                { "data": "qty", change: function(evt, data){
                    // let cek = barang.DataTable.row( this ).data().qty
                    self.counterQty = data
                    self.counterTotal = data * self.counterHarga
                    $('td:nth-child(5) .dt',evt).val(self.counterTotal);
                }},
                { "data": "note" },
                { "data": "barang_id", visible: false },
                { "data": "total" },
                { "data": "action"}
            ]
        }).fnEditor({
            action: '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary" style="color:white"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger" style="color:white"><i class="fa fa-trash"></i> Hapus</a></div>'
        })

  },
};
</script>
<style>
  .card {
    width: inherit;
  }

  select .dt .editor {
    width: 100%;
  }

  .modal-body.row.card.box-table {
    width: 100%;
  }

  .icon-edit {
    color: white;
  }
  icon-remove {
    color: white; 
  }
</style>